// Here you can add other styles

// * {
//   transition: none !important;
//   -webkit-transition: none !important;
// }
* {
  // slowdown drag and drop
  transition: all ease 0.1s;
  -webkit-transition: all ease 0.1s;
}

.show-slow-1s {
  transition: all ease 1s !important;
  -webkit-transition: all ease 1s !important;
}
.show-slow-3s {
  transition: all ease 3s !important;
  -webkit-transition: all ease 3s !important;
}

// .no-transition {
//   transition: none !important;
//   -webkit-transition: none !important;
// }

// html,
// body,
// div,
// span {
//   scrollbar-width: none !important; /* For Firefox */
//   -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
// }

// ​html::-webkit-scrollbar,
// ​body::-webkit-scrollbar,
// ​div::-webkit-scrollbar,
// ​span::-webkit-scrollbar {
//   display: none !important;
//   background-color: transparent;
//   width: 0px !important; /* For Chrome, Safari, and Opera */
// }

html::-webkit-scrollbar,
div::-webkit-scrollbar,
ul::-webkit-scrollbar {
  width: 7px;
  border-radius: 15px;
}
html::-webkit-scrollbar:hover,
div::-webkit-scrollbar:hover,
ul::-webkit-scrollbar:hover {
  width: 17px;
}

html::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb,
ul::-webkit-scrollbar-thumb {
  background: rgba(51, 153, 255, 0.666);
  border-radius: 15px;
}

html::-webkit-scrollbar-track,
div::-webkit-scrollbar-track,
ul::-webkit-scrollbar-track {
  background: transparent;
  // box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

#root {
  width: 100vw;
  min-height: 100vh;
}

.z-max {
  z-index: 2147483647 !important;
}

.disable-text-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.select-text {
  -webkit-touch-callout: text !important;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text !important;
}

.max-vh-25 {
  max-height: 25vh;
}
.max-vh-50 {
  max-height: 50vh;
}

.max-vh-75 {
  max-height: 75vh;
}

html,
body {
  overflow-x: hidden;
}

body {
  width: 100%;
}

ul {
  list-style: none;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-2em {
  font-size: 2em;
}

.p1 {
  font-family: 'Times New Roman', Times, serif;
}

.alert {
  text-align: center !important;
  text-transform: capitalize;
  border-radius: 25px !important;
  width: fit-content;
  margin: 0 auto;
}

.Toastify__toast-container {
  @extend .z-max;
  top: 44px !important;
}

.Toastify__toast {
  max-width: 97vw;
  margin: 0 auto;
  border-radius: 50px !important;
  box-shadow: 0px 7px 20px 0 #170777 !important;
}

.Toastify__close-button {
  display: none !important;
  // margin: 15px 0 0 0;
}

.Toastify__toast-body {
  font-weight: bold;
}

.Toastify__toast-body .footer {
  font-weight: bold !important;
  opacity: 0.5;
}

select:focus,
textarea:focus {
  outline: none;
}
select:focus-visible,
textarea:focus-visible {
  outline: none; // 3px solid #39f; /* That'll show 'em */
}

select {
  cursor: pointer !important;
}

.c-help {
  cursor: help;
}

option {
  font-weight: bold !important;
}

.btn {
  margin: 1px !important;
}

.btn-check:checked ~ label {
  color: #fff;
  background: #009130 !important;
}

input,
textarea,
select {
  border: 0;
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  margin: 1px !important;
  font-weight: bold !important;
  border-color: #e8e8e8 !important;
  border-radius: 25px !important;
}

input::placeholder {
  font-weight: bold;
  opacity: 0.5;
}

input[type='email'] {
  text-transform: lowercase;
}

input[type='email'] {
  text-transform: lowercase;
}

input[type='text'] {
  text-transform: capitalize;
}

input[type='radio'] ~ label {
  cursor: pointer;
}

input[type='radio'] {
  cursor: pointer;
  padding: 9px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.time-box {
  width: 230px;
  margin: 5px auto;
}

.time-box select {
  padding: 4px 15px !important;
}

.time-box .ampm-box {
  margin-top: 31px;
}

label[for*='timeam'],
label[for*='timepm'] {
  padding: 2px;
  font-weight: bold;
  // border: 1px solid #396eff;
}

.picker-input {
  padding: 3px 21px 3px 10px;
  font-size: 20px;
  color: #333;
  margin-top: 33px !important;
  border: none;
  outline: none;
  border-radius: 10px;
}

.noBorderRadius {
  border-radius: 0px !important;
}

// input[type='date']::-webkit-calendar-picker-indicator,
// input[type='datetime-local']::-webkit-calendar-picker-indicator
//  {
//   background: transparent;
//   bottom: 0;
//   color: transparent;
//   cursor: pointer;
//   height: auto;
//   left: 0;
//   position: absolute;
//   right: 0;
//   top: 0;
//   width: auto;
// }

.width190 {
  width: 190px;
  max-width: 190px;
}

.width111 {
  width: 111px;
  max-width: 111px;
}

.mw222 {
  max-width: 222px;
}

.width222 {
  width: 222px;
  max-width: 222px;
}

.min-w-fit {
  min-width: fit-content !important;
}

.minw222 {
  min-width: 222px;
}

.minh111 {
  min-height: 111px;
}

.maxh88 {
  // height: 111px;
  max-height: 88px;
}

.maxh111 {
  height: 111px;
  max-height: 111px;
}

.minh222 {
  min-height: 222px;
}

.maxh222 {
  max-height: 222px !important;
}

.minh333 {
  min-height: 333px;
}

.minh444 {
  min-height: 444px;
}

.width303 {
  width: 303px;
  max-width: 303px;
}

a,
button {
  text-decoration: none !important;
  cursor: pointer;
}

button {
  border: none !important;
}

button:disabled {
  cursor: not-allowed !important;
}

.accordion {
  margin: 0 auto;
  max-width: 95vw;
}

.rounded5 {
  border-radius: 5px !important;
}

.rounded10 {
  border-radius: 10px !important;
}

.rounded40 {
  border-radius: 40px !important;
}

.rounded50 {
  border-radius: 50px !important;
}

button,
.btn,
.rounded,
.modal .list-group,
.modal-content,
.accordion-item,
.ul-info,
.settings-list,
.card,
.nav-pills li a {
  border: 0;
  border-radius: 25px !important;
}

.sidebar-toggler {
  border-radius: 0 !important;
}

.priority-icon {
  border-radius: 50%;
  color: #b37b00;
  background: #fff0b8;
  border: 1px solid #b37b00;
}

.class-btn {
  padding-left: 0;
  padding-right: 0;
  background: transparent;
  color: #321fdb;
}

.jelly-box {
  display: grid;
  // grid-gap: 5px 10px;
  grid-template-columns: repeat(auto-fit, minmax(77px, 77px));
  width: 100%;
  // padding: 5px !important;
  justify-content: center;
  justify-items: center;
  box-sizing: border-box;
  border: none;
}

.jelly-box-calendar {
  @extend .jelly-box;
  grid-template-columns: repeat(auto-fit, 44px);
  width: 348px !important;
}

.jelly-box-inspection {
  @extend .jelly-box;
  grid-template-columns: repeat(auto-fit, 22px);
  justify-content: center;
  justify-items: center;
  padding: 5px !important;
  width: 83px !important;
}

// @media (max-width: 540px) {
//   .jelly-box-calendar {
//     min-width: 95vw !important;
//     max-width: 95vw !important;
//   }
// }

.datefilter-calendar-number {
  margin: -21px auto 0 auto;
  background: #fff;
  width: 18px;
  text-align: center;
  height: 14px;
  line-height: 1;
}

.line-h-1 {
  line-height: 1 !important;
}

.nav-pills li a,
.driver-button-map,
.jelly-btn,
.jelly-btn-sm {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  box-shadow: 0 3px 7px 0px #70777077 !important;
  // box-shadow: 0 0.5rem 1rem rgba(0, 0, 21, 0.15) !important;
}

.driver-button-map,
.jelly-btn {
  background: transparent;
  margin: 0 auto;
  padding: 5px 15px;
  width: 77px;
}

.jelly-btn {
  // padding: 8px 0 12px 0;
  padding: 10px 0;
  margin: 0 auto;
  // line-height: 18px;
  color: #3398fe;
  font-size: 0.9rem;
  // color: #321fdb;
  border-radius: 25px;
  background: transparent;
}

.jelly-btn-sm {
  width: 100%;
  padding: 4px 0;
  margin: 0 auto;
  line-height: 7px;
  color: #3398fe;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
}

.checkbox-btn {
  @extend .jelly-btn-sm;
  padding: 0;
  line-height: 0;
}

.grid-item-col-1 {
  grid-column-start: 1;
}
.grid-item-col-2 {
  grid-column-start: 2;
}
.grid-item-col-3 {
  grid-column-start: 3;
}
.grid-item-col-4 {
  grid-column-start: 4;
}
.grid-item-col-5 {
  grid-column-start: 5;
}
.grid-item-col-6 {
  grid-column-start: 6;
}
.grid-item-col-7 {
  grid-column-start: 7;
}

.jelly-btn .icon,
.jelly-btn-sm .icon {
  margin: 3px auto;
}

.gap3 {
  grid-gap: 3px;
}

.gap5 {
  grid-gap: 5px;
}

.gap25 {
  grid-gap: 25px;
}

a:hover,
button:hover,
input:hover,
select:hover,
textarea:hover {
  box-shadow: 0 0 rgba(0, 0, 0, 0) !important;
}

.badge-counter {
  @extend .rounded;
  @extend .shadow-hover;
  display: block;
  position: relative;
  margin: 0 0 -25px -12px;
  background: #fff;
  width: fit-content;
  padding: 0 7px;
  border-radius: 25px;
}

.grecaptcha-badge {
  display: none;
}

.custom-recaptcha3-terms {
  font-size: 12px;
  margin: 0 auto;
  padding: 5px;
  text-align: center;
}

.add-dest-button {
  position: relative;
  margin: -33px 3px 0 0 !important;
}

.dest-list {
  margin: 0;
  padding: 0;
  user-select: none;
}

.dest-item-box[draggable='true'] {
  @extend .z-max;
}

.dest-item[draggable='true'] {
  position: relative;
  cursor: grab;
  z-index: 214748364799 !important;
}

.dest-item,
.dest-item:active {
  @extend .grad-info-white;
  // cursor: move;
  // z-index: 21474836499 !important;
  height: 50px;
  border-radius: 25px;
}

.remove-dest-button {
  position: relative;
  display: none;
  float: left;
  margin: 0px 0 0 -33px !important;
  border: none;
}

.dest-item:hover {
  box-shadow: 0 0 19px 0px rgb(66 165 119) !important;
}

.dest-item:hover > .remove-dest-button,
.dest-item:hover > .drag-dest-handle {
  display: block;
  visibility: visible;
}

.filterGray {
  filter: grayscale(100);
}

.checkmark-icon {
  margin: 0 0 5px 0;
}

.counter-icon {
  padding: 2px !important;
  margin: 2px 0 0 0;
  color: #777 !important;
  border: 1px solid #777;
}

.feature-icon {
  margin: 0 0 0 3px;
  padding: 1px;
  border-radius: 50%;
  background: #39f;
  color: #fff;
}

.optoutsms-icon {
  color: red;
  background: transparent;
}

.optoutsms-icon::after {
  position: absolute;
  /* display: inline-block; */
  content: 'x';
  /* width: 55px; */
  /* height: 55px; */
  margin: -3px 0 0 -12px;
}

.service-acc-icon {
  background: #5aff27;
  // padding: 1px;
  border-radius: 50%;
}

.card {
  border: none;
  box-shadow: 0 0 19px 0px #8383d6 !important;
}

.dashboard-card {
  padding: 0 5px;
  min-width: 144px;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin: 3px;
}
.dashboard-card:hover {
  box-shadow: 0 0 0 0 #fff !important;
  background: #ffffff;
  transition: all ease 0.7s;
  filter: opacity(1) !important;
}

.sidebar {
  // z-index: 2147483647;
  backdrop-filter: blur(7px) brightness(33%);
  background: rgb(7 17 77 / 77%);
  // background: rgb(28 47 77 / 95%);
  // background: rgb(0 46 119 / 90%);
}

// .sidebar-backdrop {
//   display: none !important;
// }

// .sidebar-nav {
//   background: rgb(7 17 77 / 77%);
//   // background: rgb(28 47 77 / 95%);
//   // background: rgb(0 46 119 / 90%);
// }

// .sidebar-nav .nav-link.active {
//   background: rgb(4 1 100 / 87%);
// }

.sidebar-nav .nav-link {
  padding-top: 10px;
  padding-bottom: 10px;
}

.sidebar-nav .nav-link:hover {
  background: rgb(0, 30, 75);
  // background: rgb(19 31 49 / 95%);
}

.sidebar-nav .nav-link.active {
  background: rgb(0 44 111);
  // background: rgb(19 31 49 / 95%);
}

.nav-group-items {
  background: rgb(7 17 77 / 77%);
  backdrop-filter: brightness(33%);
}

.sidebar-brand {
  flex: 0 0 50px;
  background: #fff !important;
}

.sidebar .sidebar-brand-full {
  margin: -10px 10px 0 0;
}

.sidebar-brand-name {
  text-transform: uppercase;
  max-width: 55vw;
  padding: 0 0px 0px 5px !important;
  font-size: 1.2rem !important;
  font-weight: bolder !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar-area-names {
  display: inline-flex;
  align-items: center;
  align-content: center;
  font-size: 14px;
  padding: 0 5px;
  margin: -11px 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-brand {
  margin-right: 1px !important;
}

// .sidebar-brand img,
// .header-brand img {
//   transition: all 3s;
// }

.sidebar-brand,
.header-brand {
  &:hover .xperdriverLogo {
    transform: rotate(1080deg);
  }
}

.sidebar-brand,
.header-brand {
  &:hover .companyLogo {
    animation: animateHeart 1.2s infinite;
  }
}

.sub-header {
  position: fixed;
  top: 44px;
  right: 0;
  width: 100%;
  z-index: 1028;
}

.sub-header-nav {
  // @extend .mw-1000;
  margin: 3px auto 0 auto;
  align-items: center;
  justify-content: center;
}

.sub-header-nav div:first-child {
  margin-right: auto;
}
.sub-header-nav div:last-child {
  margin-left: auto;
}

.map-header {
  margin: 0 auto -33px auto;
  display: block;
  position: relative;
  z-index: 2;
  width: fit-content;
  padding: 0 5px;
  text-align: center;
}

.distance-box {
  @extend .rounded;
  @extend .bg-green;
  color: #fff;
  padding: 0 5px;
}

.heart-beat {
  animation: animateHeart 1.2s infinite;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 5px solid rgba(180, 180, 180, 0.6);
  border-top-color: rgba(0, 0, 0, 0.6);
  animation: spinner 0.6s linear infinite;
}

.header,
.header-sticky {
  padding: 0 4px !important;
  min-height: 44px;
  // margin-bottom: 5px !important;
}

.header.header-sticky {
  position: fixed !important;
  width: 100%;
  // width: -moz-available;
  // width: -webkit-fill-available;
  // width: fill-available;
  width: -moz-strech;
  width: -webkit-strech;
  width: stretch;
  @extend .shadow-gray;
}

.header-divider {
  margin: 0 auto 5px auto !important;
}

.avatar {
  width: 33px;
  height: 33px;
}

.avatar-box {
  display: grid;
  grid-template-columns: fit-content(100%) 1fr;
  grid-gap: 5px;
  align-items: stretch !important;
  align-content: center;
  border: none;
  border-radius: 25px !important;
  // padding: 3px;
  // background: linear-gradient(33deg, #e7f7ff, rgba(255, 255, 255, 0));
  // box-shadow: -7px 7px 17px 0px #e7e7ff !important;
}

.vehicle-avatar-box {
  @extend .w-fit;
  margin: 0 auto;
}

.inspectionlist-box {
  // @extend .max-vh-50;
  display: inline-block;
  text-align: left;
  margin: 0 auto;
  padding: 0;
  // overflow: scroll;
  // background: #d1ebfc;
  // border-radius: 25px;
  // box-shadow: 1px 1px 13px inset;
}

.inspection-field-box {
  @extend .avatar-box;
  justify-items: flex-start;
  align-items: center !important;
  overflow: hidden;
  // margin: 0 auto 1px auto;
}

.inspection-field-box .label {
  line-height: 17px;
}

.highlight-onhover:hover {
  @extend .grad-golden-white;
}

.isEven:hover,
.isEven:nth-child(even):hover {
  @extend .grad-golden-white;
}

.isEven:nth-child(even) {
  // background: linear-gradient(45deg, #77777770, transparent);
  background: linear-gradient(45deg, rgba(119, 119, 119, 0.09), transparent);
}

.company-logo {
  display: block;
  width: 77px;
  height: 77px;
  margin: 0 auto;
  cursor: pointer;
  border-radius: 10px;
}

.user-avatar {
  display: block;
  margin: 0 auto;
}

.avatar,
.user-avatar {
  border-radius: 50%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.delete-avatar {
  visibility: hidden;
  padding: 0 7px;
}

.delete-avatar:hover,
.user-avatar:hover ~ .delete-avatar {
  visibility: visible;
}

.breaktext {
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  padding: 0 0px 5px 0px;
}

.container-fluid {
  min-height: auto !important;
}

.body {
  min-height: 100vh;
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.main-wrapper {
  margin: 44px auto 0 auto;
}

.flip-x {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.rotate-North {
  transform: rotate(90deg);
}

.rotate-South {
  transform: rotate(-90deg);
}

.mb-n5px {
  margin: 0 0 -5px 0 !important;
}

.w-fit {
  width: fit-content !important;
}
.w-fill {
  width: fill-content !important;
}

.w-222 {
  width: 222px !important;
}

.w-111 {
  width: 111px !important;
}

.w-88px {
  width: 88px !important;
}

.w-77px {
  width: 77px !important;
}

.w-66px {
  width: 66px !important;
}

.w-50px {
  width: 50px !important;
}

.w-30px {
  width: 30px !important;
}

.h-55 {
  height: 55px !important;
}

.h-77 {
  height: 77px !important;
}

.w-82px {
  width: 82px !important;
}

.h303 {
  height: 303px !important;
}

.min-w-333 {
  min-width: 333px !important;
}

.minw303 {
  min-width: 303px !important;
}

.mw303 {
  max-width: 303px;
}

.mw101 {
  max-width: 101px;
}

.max-vw-100 {
  max-width: 100vw;
}

.vw-98 {
  width: 98vw;
}
.max-vw-98 {
  max-width: 98vw;
}

.search {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.serachinput {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.breadcrumb-item a {
  background: #dfdfdf;
  margin: 2px;
  padding: 5px 10px 8px 10px;
  border-radius: 10px;
  font-weight: bold;
}

.pending {
  background-color: #fff;
  // background-color: #f1f7ff;
}

// .pending {
//   background-color: #f1fff9;
// }

.returningTo {
  background-color: #ece7e0;
}

.newTripAlert {
  background-color: #f2a6aa;
}

.ended {
  background-color: #e6e6e6;
}

.canceled {
  background-color: #ffeeed;
}

.isDeactivated {
  text-shadow: 0px 0px 6px white;
  background-color: #777777;
}

.responsive-list {
  border-radius: 0;
  padding-right: 0;
}

.responsive-list li:first-child {
  background-color: rgb(196, 215, 255);
}

.responsive-list li,
.responsive-card {
  display: grid;
  grid-gap: 5px 10px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  width: 100%;
  padding: 10px !important;
  justify-content: center;
  justify-items: center;
  box-sizing: border-box;
}

.responsive-payment-plan {
  display: grid;
  grid-gap: 5px 10px;
  grid-template-columns: repeat(auto-fit, minmax(177px, 1fr));
  width: 100%;
  padding: 10px !important;
  justify-content: center;
  justify-items: center;
  box-sizing: border-box;
}

.dashboard-card-box {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fit, 150px);
  // grid-template-columns: repeat(auto-fit, minmax(133px, 1fr));
  width: 100%;
  padding: 10px !important;
  justify-content: center;
  justify-items: center;
  box-sizing: border-box;
  max-width: 1200px;
  margin: 0 auto;
}

.mw-500 {
  max-width: 500px;
}

.mw-555 {
  max-width: 555px;
}

.mw-1000 {
  max-width: 1000px;
}

.grid-1-col {
  display: grid;
  grid-template-columns: max-content;
}

.grid-1fr {
  display: grid;
  grid-template-columns: 1fr;
}

.grid-2-col {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.row {
  --cui-gutter-x: 0 !important;
}

.collapse-button {
  border: none;
  margin: -5px 0 0 0 !important;
}

.collapse-button:active .collapse-list {
  height: auto;
}

.collapse-list {
  height: 45px;
  overflow: hidden;
}

.expanded {
  height: auto;
}

form {
  padding: 10px;
  max-width: 1200px;
  margin: 0 auto !important;
  border: 0;
}

.mycol {
  width: fit-content;
  min-width: 333px;
  max-width: 360px;
}

.form-control {
  border: 0;
}

.form-wrapper-sm {
  max-width: 444px !important;
  margin: 0 auto;
}

.form-wrapper-sm input {
  font-weight: bold;
  font-size: 18px;
}

.liveLocationModal,
.pdfModal,
.addFormModal,
.dateModal,
.tripOptionsModal,
.tripNotesModal,
.filterTripByDateModal,
.addEditTripModal,
.filterTripsByCreatorModal,
.filterTripsByDriverModal {
  position: fixed !important;
  top: 0px !important;
  overflow: hidden;
  z-index: 2147483646;
}

.filterTripsByCreatorModal .modal-body,
.filterTripsByDriverModal .modal-body {
  max-width: 100vw;
  min-height: 555px;
  height: fit-content;
}

.liveLocationModal,
.pdfModal {
  overflow: hidden;
}

.liveLocationModal .modal-content,
.pdfModal .modal-content {
  width: 95vw !important;
}

.pdfModal .modal-content {
  max-width: fit-content;
  margin: 0 auto;
}

.modal {
  z-index: 214748364 !important;
  // z-index: 2147483646 !important;
}

.modal-dialog-scrollable {
  height: 100vh;
}

.resize {
  resize: vertical;
  overflow: auto;
}

.liveLocationModal .modal-content,
.pdfModal .modal-content,
.addFormModal .modal-content,
.dateModal .modal-content,
.tripOptionsModal .modal-content,
.tripNotesModal .modal-content,
.filterTripByDateModal .modal-content,
.filterTripsByCreatorModal .modal-content,
.filterTripsByDriverModal .modal-content {
  box-shadow: 0 0 8px 8px #28284ff2 !important;
  // box-shadow: 0 0 8px 8px #444444 !important;
  @extend .grad-info-white;
}

.addEditTripModal .modal-content {
  box-shadow: 0 0 8px 8px #28284ff2 !important;
}

.dateModal .modal-content,
.modal-dialog {
  @extend .minw303;
  width: fit-content !important;
  margin: 0 auto !important;
}

.filterTripByDateModal .modal-content {
  min-height: 250px;
}

.filterTripByDateModal .modal-body {
  padding: 5px;
}

.addEditTripModal .modal-content {
  @extend .minw303;
  width: fit-content;
  margin: 0 auto;
  padding: 5px auto;
  overflow: visible !important;
  max-width: 99vw;
  // min-height: 606px;
}

.addEditTripModal .modal-dialog,
.confirmActionModal .modal-dialog {
  // overflow: visible !important;
  background: rgb(0 33 77 / 77%);
  width: 100vw !important;
  max-width: 100vw !important;
}

.liveLocationModal .modal-body,
.pdfModal .modal-body {
  padding: 0 !important;
}
.liveLocationModal .modal-dialog,
.pdfModal .modal-dialog {
  max-width: 95% !important;
}

.filterTripByDateModal .modal-dialog,
.addFormModal .modal-dialog,
.tripNotesModal .modal-body,
.dateModal .modal-dialog {
  margin: 0 auto !important;
  max-width: 97vw !important;
  // max-width: 888px !important;
}
.filterTripByDateModal .modal-body,
.addFormModal .modal-body,
.dateModal .modal-body {
  margin: 0 auto !important;
}

.addEditTripModal .modal-body,
.addEditTripModal .modal-footer {
  // overflow: visible !important;
  padding: 0;
}

.addEditTripModal .modal-body {
  display: flex !important;
  flex-direction: column !important;
}

.addEditTripModal .modalBodyWrapper {
  flex-grow: 1;
}

.addEditTripModal .modal-header {
  display: none;
}

.addEditTripModal .card-title {
  border-radius: 25px;
}

.addFormModal .modal-body {
  text-align: left;
}

.addFormModal .form-label {
  float: left;
}

.liveLocationModal iframe {
  width: 100% !important;
  height: 90vh !important;
}

.onTripModal,
.confirmActionModal {
  position: fixed;
  left: 0;
  top: 0;
  transform: none !important;
  top: 0 !important;
  // width: 100vw !important;
  // height: 100vh !important;
  // background: rgba(0, 0, 0, 0.82);
}

.onTripModal .modal-dialog,
.confirmActionModal .modal-dialog {
  // position: fixed;
  // top: 0;
  // left: 0;
  // width: 100vw !important;
  max-width: 100vw;
  // height: 100vh !important;
  margin: 0 auto;
  // padding: 0 !important;
}

.modal-header {
  padding: 3px;
  background: transparent !important;
  border: none;
}

.border-bottom-1 {
  border-bottom: 1px solid rgb(221 221 255 / 95%) !important;
}

.border-bottom-4 {
  border-bottom: 4px solid #f7f7f7 !important;
}

.border-radius-0 {
  border-radius: 0 !important;
}

// .modal-content {
//   background: transparent !important;
//   // background: rgba(255, 255, 255, 0.88) !important;
// }

.modal-header .btn-close {
  border-radius: 50%;
  padding: 15px;
  box-shadow: 0 0 20px 4px #bb4c4c !important;
  background: #fff
    var(
      --cui-btn-close-bg,
      url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27rgba%2844, 56, 74, 0.95%29%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e')
    )
    center/1em auto no-repeat !important;
}

.btn-close {
  background-color: #ffc5c5;
}

.onTripModal .modal-header {
  display: none !important;
}

.onTripModal .modal-header,
.confirmActionModal .modal-header {
  background: #fff;
  border-radius: 50%;
}

.modal-content {
  transform: none !important;
  height: fit-content !important;
  // min-height: 404px !important;
}

.modal-title {
  padding: 0 11px;
  text-align: left;
}

.onTripModal .modal-content {
  background: transparent !important;
  box-shadow: 0 0 20px 0px #06003b;
}

.confirmActionModal .modal-content {
  transform: none !important;
  // flex-wrap: wrap;
  // height: 100vh;
  width: 100vw !important;
  min-height: 404px !important;
  margin: 0 auto;
  border: 0 !important;
  border-radius: 0 !important;
  background: transparent;
  align-content: center !important;
  align-items: center !important;
}

.onTripModal .modal-body,
.confirmActionModal .modal-body {
  display: grid;
  margin: 0 auto;
  padding: 2px !important;
  // display: flex;
  // flex-wrap: wrap;
  // align-content: center !important;
}

.confirmActionModal .modal-body {
  min-height: 505px;
}

.onTripModal .modal-body ul {
  @extend .minw303;
  max-width: 98vw;
}

.disabled {
  cursor: not-allowed;
}
.confirmActionModal .modal-body ul:first-child {
  @extend .grad-info-white;
}

.onTripModal .modal-body ul:first-child,
.confirmActionModal .modal-body ul:first-child {
  // padding: 5px;
  background: #fff;
  // box-shadow: 0 0 8px 8px #8c8c8c !important;
  // box-shadow: 0 0 7px 0px #77707707 !important;
}

.confirmActionModal .modal-body ul {
  @extend .rounded;
  @extend .minw303;
  display: grid;
  align-items: center;
  width: fit-content;
  max-width: 98vw;
  height: fit-content;
  margin: 19px;
  box-shadow: 0 0 20px 0px #060039 !important;
}

.toogle-trip-button {
  width: 100%;
  // max-width: 444px;
  margin: 0 !important;
  padding: 22px 12px;
  font-weight: bold !important;
  text-transform: uppercase !important;
  text-align: center;
  border: 0 !important;
  font-size: 33px !important;
}

.modalOnTripNote {
  display: block !important;
  max-width: 444px;
  margin: 0;
  font-weight: bold;
  text-transform: capitalize !important;
  color: #009130;
  font-weight: bold;
}

.confirm-button {
  width: 111px;
  height: 111px;
  background: crimson;
  border-radius: 50%;
  border: none;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  // padding: 50px 40px !important;
  text-transform: uppercase !important;
  box-shadow: 0px 3px 7px 0px rgb(101 101 101 / 77%) !important;
  // box-shadow: 0 0 7px 0px #77707707 !important;
}

.confirm-button:hover {
  box-shadow: 0px 3px 3px 0px rgb(101 101 101 / 77%) !important;
}

.google-suggestion-box {
  @extend .rounded;
  @extend .shadow-gray;
  width: 100%;
  position: absolute;
  z-index: 9;
  background: #fff;
  overflow: hidden;
}

.google-suggestion {
  padding: 6px 0px;
  margin: 0px auto;
}

.google-suggestion button {
  display: flex;
  padding: 7px 7px 7px 3px;
  align-items: center;
  background: transparent;
}

.google-suggestion:hover {
  @extend .shadow;
  @extend .rounded;
  // box-shadow: 0px 3px 3px 0px rgb(101 101 101 / 77%) !important;
}

.form-label {
  /* display: inline-flex; */
  font-size: 12px;
  background: rgb(227 227 227 / 17%);
  margin: 0 0 -7px 0;
  padding: 2px 11px;
  border-radius: 25px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0px -9px 19px 0px rgb(170 170 170 / 27%);
}
// .form-label {
//   display: inline-flex;
//   font-size: 12px;
//   background: #e3e3e329;
//   margin: 0 0 0 0;
//   padding: 2px 15px;
//   border-radius: 25px;
//   align-items: center;
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

label.bg-warning,
.mandatory {
  background: linear-gradient(179deg, #ffe09c, #fff);
}

.input-group {
  align-items: center !important;
}

.list-group {
  max-width: 700px;
}

.tripOptionsButton {
  display: inline-flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  min-width: 111px;
  border: none;
}

// @media screen and (min-width: 840px) {
//   .tripOptionsButton {
//     padding: 5px 1em 5px 5px;
//   }
// }

.full-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483646;
  // z-index: 99999999;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  // background: #000000a5;
  // backdrop-filter: blur(7px);
}

.show-slow {
  transition: 3s;
  transition-timing-function: ease-out;
  opacity: 1 !important;
}

.show-slow {
  transition: 3s;
  transition-timing-function: ease-out;
  opacity: 1 !important;
}

.show-easy {
  transition: 0.3s;
  transition-timing-function: ease-in-out;
  opacity: 1 !important;
}

.edit-btn {
  @extend .shadow-gray;
  @extend .grad-info;
  color: #39f;
  padding: 1px 5px;
}

.fallback-spinner,
.screen-center {
  position: fixed;
  top: 50%;
  left: 50%;
}

.custom-select,
.custom-select-form {
  // @extend .w-fit;
  text-transform: capitalize;
  width: 100%;
  // max-width: 95vw;
  border: none;
  border-radius: 25px !important;
  cursor: pointer;
  // z-index: 9;
}

.custom-select div[class$='menu'],
.custom-select-form div[class$='menu'] {
  // display: block;
  // position: relative;
  z-index: 99999999;
}

.hideIndicators {
  div[class$='IndicatorsContainer'] {
    display: none !important;
  }
  div[class$='indicatorContainer'] {
    display: none !important;
  }
}

span[class$='indicatorSeparator'] {
  display: none !important;
}

div[id$='listbox'] {
  @extend .w-fit;
  @extend .minw222;
  @extend .grad-info-white;
  @extend .rounded;
  border: none !important;
  // position: relative;
  position: absolute;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  z-index: 99999;
}

.control-grad-info div[class$='control'] {
  @extend .grad-info-white;
}

.custom-select div[class$='control'] {
  // @extend .w-fit;
  // @extend .grad-info-white;
  // border-radius: 7px;
  border: none !important;
  border-color: transparent !important;
  // height: 33px !important;
  // min-height: 33px !important;
  border-radius: 25px !important;
  cursor: pointer;
  // background: linear-gradient(358deg, white, #ffffff00);
  outline: none !important;
  box-shadow: none !important;
  // background: transparent !important;
}

.custom-select-form div[class$='control'] {
  // @extend .w-fit;
  // @extend .grad-info-white;
  border: none !important;
  border-color: transparent !important;
  min-height: 36px !important;
  // height: 36px !important;
  border-radius: 25px !important;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  // background: transparent !important;
}

.custom-select div[class$='menu'] > div,
.custom-select-form div[class$='menu'] > div {
  @extend .shadow-gray;
  max-height: 303px !important;
  overflow: auto !important;
}

.custom-select.no-min-height div[class$='control'] {
  padding: 0;
}

.custom-select:not(.no-min-height) div[class$='control'] {
  height: 33px !important;
  min-height: 33px !important;
}

.custom-select div[class$='placeholder'],
.custom-select div[class$='ValueContainer'],
.custom-select div[class$='indicatorSeparator'],
.custom-select div[class$='singleValue'],
.custom-select div[class$='indicatorContainer'] {
  margin: -3px 0 0 0;
}

.custom-select-form div[class$='placeholder'],
.custom-select-form div[class$='ValueContainer'],
.custom-select-form div[class$='indicatorSeparator'],
.custom-select-form div[class$='singleValue'],
.custom-select-form div[class$='indicatorContainer'] {
  margin: -1px 0 0 0;
}

/*  elements with an href ending ".org", case-insensitive */

div[class$='multiValue'] {
  border-radius: 15px;
  margin: 2px 7px 2px -2px;
  box-shadow: -2px 2px 5px 0 #9da5b1;
  background: linear-gradient(45deg, #a7d2ff, #fff);
}

div[class$='MultiValueGeneric'] {
  padding-left: 10px;
}

div[class$='MultiValueRemove'] {
  @extend .opacity05;
  border-radius: 11px;
  padding-left: 5px;
  padding-right: 5px;
}
div[class$='multiValue']:hover div[class$='MultiValueRemove'],
div[class$='MultiValueRemove']:hover {
  opacity: 1 !important;
}

// .weekday {
//   width: 101px;
// }

#onTripModalMap {
  border-radius: 25px;
  overflow: hidden;
}

.onlymap {
  width: 100vw;
  height: 100vh;
  /* float: left; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #fff;
}

.map-handlers {
  @extend .w-fit;
  z-index: 9;
  display: block;
  position: relative;
  margin: -11px 0 -55px -15px;
  overflow: visible;
  padding: 15px;
}

.map-handlers button {
  @extend .shadow-gray;
  overflow: visible;
  padding: 0 !important;
  margin: 0 5px;
  background: transparent !important;
  /* overflow: visible; */
  line-height: 1px !important;
}

.map-handlers svg {
  border-radius: 50%;
  overflow: visible;
  margin: 0;
}

.map-dot {
  width: 22px;
  height: 22px;
  // background: #00852c;
  padding: 0;
  font-size: 13px;
  margin: 0px 0 16px 0;
  font-weight: bold;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-content: center;
  border-radius: 50%;
}

.map-label-datetime {
  // @extend .shadow-dark;
  // display: none;
  /* height: 19px; */
  position: relative;
  background: rgb(255, 255, 255);
  font-size: 12px;
  padding: 0 7px 3px 7px;
  margin: -20px 0 0 0;
  // margin: 0 0 22px 0;
  color: #000000;
  text-align: center;
  justify-content: center;
  align-content: center;
  border-radius: 15px;
  min-width: 25px;
  min-height: 25px;
  // max-width: 144px;
}

.cursor-help {
  cursor: help;
}

.map-label-datetime:hover {
  // @extend .shadow-dark;
  @extend .bg-info;
  color: #fff;
  display: block;
  cursor: help;
  margin: 0px 0 33px 0;
}

// .map-label-datetime::after {
//   content: 'i';
//   // position: absolute;
//   // width: 10px;
//   // height: 10px;
// }

.map-label-drivername {
  @extend .w-fit;
  margin: 2px auto 0 auto;
  border-radius: 17px;
  color: #fff !important;
  background: #000000;
  cursor: pointer;
}

.map-label-customername {
  @extend .w-fit;
  @extend .rounded;
  margin: 5px auto;
  padding: 4px 7px;
  color: #fff !important;
  background: #005b1e;
  cursor: help;
}

.map-label-drivername,
.fuellevel,
.map-label-name,
.map-label-date {
  display: none;
}

.map-label-idx {
  // @extend .rounded;
  font-weight: bold;
  // width: 10px;
  color: #fff;
  text-align: center;
}

.map-label-idx:hover > .map-label-drivername,
.map-label-idx:hover > .fuellevel,
.map-label-idx:hover > .map-label-name,
.map-label-idx:hover > .map-label-date,
.map-label-datetime:hover > .map-label-drivername,
.map-label-datetime:hover .fuellevel,
.map-label-drivername:hover .fuellevel,
.map-label-vehicle:hover .fuellevel,
.map-vehicle-label:hover .fuellevel,
.fuellevel:hover,
.map-label-datetime:hover > .map-label-name,
.map-label-datetime:hover > .map-label-date {
  display: block;
  color: #fff !important;
}

.map-label-idx:hover,
.map-label-datetime:hover > .map-label-idx {
  display: none;
}

.map-label-idx label {
  @extend .rounded;
  padding: 0 3px;
}
// .map-label-datetime:hover:after {
//   content: '';
// }

.map-vehicle-label {
  // @extend .shadow-hover;
  /* height: 19px; */
  // background: rgb(255, 255, 255);
  position: absolute;
  left: 25px;
  padding: 0 7px 3px 7px !important;
  font-size: 12px;
  // margin: 22px 0 -22px -100px;
  // margin: 0 0 0 121px;
  // color: #000000;
  text-align: left;
  justify-content: center;
  align-content: center;
  border-radius: 10px;
  min-width: 99px !important;
}

.map-vehicle-label:hover {
  // @extend .grad-info-white;
  // width: 154px !important;
  // margin: 0 0 0 171px;
  cursor: help;
  // width: fit-content !important;
}

.map-vehicle-label .map-vehicle-driver-name,
.map-vehicle-label .map-vehicle-date {
  display: none;
}

.map-vehicle-label:hover > .map-vehicle-driver-name,
.map-vehicle-label:hover > .map-vehicle-date,
.map-vehicle-name:hover > .map-vehicle-driver-name,
.map-vehicle-name:hover > .map-vehicle-date,
.map-vehicle-driver-name:hover,
.map-vehicle-date:hover {
  // @extend .grad-info-white;
  display: block;
}

.map-facility-label {
  // @extend .grad-golden-white;
  // @extend .shadow-gray;
  /* height: 19px; */
  display: block;
  // background: rgb(255, 255, 255);
  padding: 0 7px 3px 7px;
  font-size: 12px;
  margin: -40px 0 0 0;
  color: #ffffff;
  text-align: center;
  justify-content: center;
  align-content: center;
  border-radius: 10px;
  // max-width: 144px;
}

.ol-attribution,
.ol-rotate-reset {
  display: none;
}

.ol-zoom,
.ol-full-screen {
  float: right !important;
}

.bg-blur {
  // @extend .shadow-dark;
  // background: linear-gradient(181deg, #ffffff, rgb(255 255 255 / 27%));
  background: radial-gradient(#ffffff, #ffffff00);
  border-radius: 50%;
  overflow: visible;
  backdrop-filter: opacity(0.7) blur(77px);
}

.ul-info,
.settings-list {
  display: inline-block !important;
  text-align: left !important;
  padding: 10px;
}

.ul-info li {
  margin: 10px auto;
}

.settings-list {
  @extend .w-fit;
  // @extend .min-w-333;
  padding: 0;
  margin: 0 auto;
  float: left;
}

.settings-list li {
  // @extend .shadow-hover;
  @extend .isEven;
  @extend .rounded;
  // @extend .w-fit;
  display: flex;
  // width: 100%;
  margin: 15px auto;
  text-align: left;
}

.settings-list li button {
  display: inline-flex;
  margin: 0 0 0 -5px;
  background: transparent;
}

.list-group-item {
  background: transparent;
}
// .customer-name,
// .driver-name {
//   padding: 0 10px 0 0 !important;
// }

.driver-name,
.customer-name,
.modify-time-button,
.modify-tripnotes-button {
  padding: 0;
  background: transparent !important;
}

.driver-name:hover,
.customer-name:hover,
.modify-time-button:hover,
.modify-tripnotes-button:hover {
  background: #d7ecf0 !important;
  border-radius: 10px;
  // font-weight: bold;
  cursor: pointer;
}

.modify-tripnotes-button {
  font-weight: bold;
  text-transform: capitalize;
  color: #009130;
}

.note-content {
  white-space: break-spaces !important;
  float: left;
  text-align: left;
  font-weight: bold;
}

.note-author {
  @extend .fs-13;
  @extend .opacity05;
  color: rgb(90 90 90 / 90%);
  font-weight: lighter;
}

.note-author .icon {
  padding: 1px;
}

.customer-name {
  display: inline-flex;
  align-items: center;
  float: left;
  border: 0;
  padding: 0;
  font-weight: bold;
  background: transparent;
}

// .vehicle-name {
//   min-width: 133px;
// }

.inline-list-button {
  float: left;
  padding: 5px 20px 5px 5px;
  margin: 0;
  border-radius: 25px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
}

.inline-list-button svg {
  margin-right: 3px;
}

.inline-list-button:hover {
  background: #d7ecf0;
}

.mw-50vw {
  max-width: 50vw !important;
}

.img13 {
  width: 13px;
  height: 13px;
}

.img16 {
  width: 16px;
  height: 16px;
}

.opacity1 {
  filter: opacity(1) !important;
}

.opacity0 {
  filter: opacity(0) !important;
}

.opacity05 {
  filter: opacity(0.5) !important;
}

.opacity02 {
  filter: opacity(0.2) !important;
}

.opacity07 {
  filter: opacity(0.7) !important;
}

.buy {
  border: none;
  padding: 10px 33px;
  width: 100%;
  background: linear-gradient(to right, #003087, #009cde);
  height: 44px;
  border-radius: 50px;
  margin: 2rem auto 15px auto !important;
  color: #fff;
  display: block;
  // transition: 0.4s ease;

  &:hover {
    transform: translateY(-4px);
  }

  &:disabled {
    background: #c1c1c1;
  }
}

.driver-route-btn-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  justify-content: center;
  align-content: center;
  grid-gap: 5px;
}

.trajectory-idx-btn {
  @extend .fs-10;
  @extend .shadow-hover;
  margin: 0 3px 4px 0;
  font-weight: bold;
  padding: 2px 3px !important;
  min-width: 17px;
}

.create-trajectory-btn {
  @extend .fs-22;
  @extend .shadow-hover;
  margin: 0 3px 4px 0;
  font-weight: bold;
}

.driver-route-btn {
  display: flex;
  flex-wrap: nowrap;
  text-align: center;
  width: 150px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  border: 0;
}

.driver-route-btn span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 3px 0;
  text-align: center;
}

.progress {
  @extend .w-100;
  // box-shadow: 1px 1px 8px #003087;
  box-shadow: 1px 8px 17px #003087;
}
.progress p {
  @extend .w-100;
  text-align: center;
  color: #000000;
  // box-shadow: 1px 1px 8px #003087;
  // box-shadow: 1px 8px 17px #003087;
}

.companyLogo-container,
.carloader-container {
  display: grid;
  // flex-wrap: wrap;
  height: 80vh;
  width: 100%;
  margin: -50px auto 0 auto;
  align-content: center !important;
  justify-items: center;
  justify-content: center;
  background: transparent;
  text-align: center;
  overflow: visible;
}

.logoImgLoader {
  // background: #ffffffb1;
  // border-radius: 50%;
  overflow: visible;
  // backdrop-filter: blur(5px);
  // box-shadow: 0px 0px 10px 10px #3a58ffb1;
}

.carloader,
.companyLogoLoader {
  position: relative;
  border: 5px solid #fff0;
  z-index: 99999999;
  justify-self: center;
  display: grid;
  align-items: end;
  justify-items: center;
  // align-content: center;
  justify-content: center;
  grid-template-rows: 50px;
  grid-template-columns: 50px;
  overflow: visible;
}
.carloader::before {
  position: absolute;
  z-index: -1;
  top: 12px;
  /* right: -1.5em; */
  /* bottom: -1.5em; */
  left: -37px;
  border: inherit;
  border-color: rgb(66 100 151 / 95%);
  background: inherit;
  background-clip: border-box;
  filter: blur(9px);
  /* clip-path: inset(0); */
  content: '';
  width: 69px;
  height: 22px;
  animation: 1s linear infinite smoke;
}

.carloader .car {
  grid-area: 1;
  grid-row: 1;
  grid-column-start: 1;
}

.carloader .wheels {
  display: grid;
  grid-area: 1;
  grid-column-start: 1;
  grid-template-columns: 11px 11px;
  grid-gap: 12px;
  justify-items: center;
  justify-content: center;
  width: 100%;
}

.grid-auto {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, 300px);
  justify-content: center;
}

.grid-auto-fit {
  display: grid;
  grid-template-columns: repeat(auto-fit, 105px);
  // grid-template-columns: repeat(auto-fit, minmax(105px, 1fr));
}

.grid-auto-fit-min-50 {
  display: grid;
  grid-template-columns: repeat(auto-fit, 50px);
  // grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
}

.grid-auto-fit-min-50 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
}

.offlinenotification {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: fit-content;
  /* margin: 44px 0 -44px 0; */
  padding: 4px 8px 8px 8px;
  border-radius: 0 0 15px 15px;
  color: #fff;
  background: #333;
  z-index: 2147483647;
}

.modalOfflineNotification {
  /* position: fixed; */
  background: #333;
  /* width: 100vw; */
  color: #fff;
  text-align: center;
  padding: 12px;
  border-radius: 25px;
}

.shadow-dark {
  box-shadow: 0 0 20px 4px #000000 !important;
}

.shadow-gray {
  // box-shadow: 0 0 8px 2px #d2d2d2 !important;
  // box-shadow: 0px 3px 12px 2px #d9d9d9 !important;
  box-shadow: 0 3px 7px 0px #70777077 !important;
}

.text-red {
  color: #d20000 !important;
}

.bg-red {
  background: #d20000 !important;
}

.shadow-danger {
  box-shadow: 0 0 20px 4px #bb4c4c !important;
}

.shadow-purple {
  box-shadow: 0 0 19px 0px #8383d6 !important;
}

.shadow-soft {
  box-shadow: -7px 7px 17px 0px #e7e7ff !important;
}

.shadow-soft8 {
  box-shadow: -8px 8px 8px 0px #e8e8e8 !important;
}

.shadow-soft,
.shadow-soft8:hover {
  box-shadow: -8px 8px 8px 0px #e8e8e888 !important;
}

// must be the last shadow
.shadow-0 {
  box-shadow: 0 0 0 transparent !important;
}

.h-fit {
  height: fit-content !important;
}
.h-auto {
  height: auto !important;
}

.bg-light-blue {
  background: rgb(178 210 222) !important;
}

.grad-light-blue {
  // background: linear-gradient(33deg, rgb(133 118 255 / 17%) 0%, rgba(220, 249, 255, 0.33) 100%);
  background: linear-gradient(33deg, rgb(203 235 255), rgb(250 254 255));
}

.grad-dark-blue {
  background: linear-gradient(45deg, #00072d, rgb(10 55 77 / 0%));
  color: rgb(139 160 182) !important;
}

.grad-info {
  background: linear-gradient(45deg, #a7e5ff, transparent);
  color: rgb(0, 109, 218) !important;
}

.grad-info-white {
  background: linear-gradient(45deg, #d3f1ff, #ffffffed);
  color: rgb(0, 109, 218) !important;
}
.grad-info:hover,
.grad-info-white:hover {
  color: rgb(0, 109, 218);
}
// .grad-info-white {
//   background: linear-gradient(45deg, #dcf4ff, #ffffffed);
// }

.bg-green {
  background: rgb(44, 144, 4) !important;
}

.bg-light-green {
  background: rgb(159, 235, 162) !important;
}

.text-green {
  color: rgb(44, 144, 4) !important;
}

.text-deep-green {
  color: rgb(29, 101, 0) !important;
}

.bg-deep-green {
  background: #003107 !important;
}

.grad-success {
  @extend .text-green;
  background: linear-gradient(45deg, #c9ffd2, transparent);
}

.grad-success-white {
  @extend .text-green;
  background: linear-gradient(45deg, #c9ffd2, #fff) !important;
}

.grad-deep-green {
  // @extend .text-green;
  background: linear-gradient(45deg, #004f0c, #00fa3f) !important;
}

.grad-deep-blue {
  color: #fff !important;
  background: linear-gradient(45deg, #000841, #0a91ff) !important;
}

.grad-warn {
  color: #6b4808 !important;
  background: linear-gradient(45deg, #ffd888, transparent) !important;
}

.grad-warn-white {
  color: #6b4808;
  background: linear-gradient(45deg, #ffd888, #fff) !important;
}

.grad-brown {
  color: #fff !important;
  background: linear-gradient(45deg, #2c2311, #d79e00) !important;
}

.bg-brown {
  color: #fff !important;
  background: #966700 !important;
}

.text-orange {
  color: #ff7200;
}

.grad-orange {
  background: linear-gradient(45deg, #f1ca94, transparent);
}

.grad-orange-white {
  background: linear-gradient(45deg, #f1ca94, #fff);
}

.grad-danger {
  background: linear-gradient(45deg, #ffc8c8, transparent);
  // background: linear-gradient(45deg, #ff8989, transparent);
  // background: linear-gradient(45deg, #ffd3d3, transparent);
  color: red;
}

.grad-danger-white {
  background: linear-gradient(45deg, #ffd3d3, #fff) !important;
  color: red !important;
}

.grad-gray {
  color: #333;
  background: linear-gradient(45deg, #d9d9d9, transparent);
}
.grad-gray-white {
  color: #333;
  background: linear-gradient(45deg, #d9d9d9, #fff);
}

.grad-priority {
  background: linear-gradient(45deg, #fff8d3, transparent);
}

.text-gold {
  color: #9a5909;
}
.grad-golden {
  @extend .text-gold;
  background: linear-gradient(45deg, #fff5c6, transparent);
}

.grad-golden-white {
  @extend .text-gold;
  background: linear-gradient(45deg, #fff5c6, #fff) !important;
}

.text-purple {
  color: blueviolet;
  // color: #986dff;
}
.bg-purple {
  background: blueviolet !important;
  // color: #986dff;
}

.grad-service,
.grad-purple {
  @extend .text-purple;
  background: linear-gradient(45deg, #d7c6ff, transparent);
}

.grad-purple-white {
  @extend .text-purple;
  background: linear-gradient(45deg, #d7c6ff, #fff);
}

.grad-deep-purple {
  color: #fff;
  background: linear-gradient(45deg, #3b00c5, #9061ff);
}

.bg-purple {
  background: #986dff;
}

.shadow-hover {
  box-shadow: 0 1px 3px 0px #70777077 !important;
}

.font-italic {
  font-style: italic !important;
}

.shadow:hover,
.shadow-gray:hover,
.jelly-btn:hover,
.jelly-btn-sm:hover,
.nav-pills li a:hover
// .ol-zoom-in:hover,
// .ol-zoom-out:hover
{
  @extend .shadow-hover;
  //   // box-shadow: 0 3px 7px 0px #70777077 !important;
}

// .jelly-btn:hover,
// .jelly-btn-sm:hover {
//   animation: jello 1s;
//   transform-origin: center;
// }

.jumpInfiniteAnimation {
  animation: jumpInfinite 1.5s infinite;
}

.close {
  padding: 0px 4px;
  color: red;
  background: #ffb6b6;
  box-shadow: 0 0 13px 0px #333;
}

.cancel-trips-box {
  display: none;
}

.toggle-map-btn {
  color: #fff;
  font-weight: bold;
  background: transparent;
  padding: 3px 7px;
  margin: 0 1px;
  // box-shadow: 0 0 14px 0px #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 999;
}

.map-wrapper .close {
  display: block;
  float: right;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 4px 8px;
  background: #ffb6b6;
  z-index: 9991;
  box-shadow: 0 0 14px 0px #333;
}

.map-trips-wrapper {
  overflow: hidden;
  min-height: 100vh;
  margin-top: 80px;
  // margin: 80px auto 0 auto;
}

.trips-wrapper {
  padding: 0 0 0 1px;
}

.ol-zoom-in,
.ol-zoom-out,
.ol-full-screen-false,
.ol-full-screen-true {
  // @extend .shadow-dark;
  // @extend .grad-success-white;
  @extend .bg-info;
  // color: #fff;
  // padding: 0 9px;
  // margin: 7px 10px;
  // // font-size: 22px;
  // font-weight: bold;
  // line-height: 30px;
  color: #fff;
  /* padding: 0 1px; */
  margin: 3px 6px;
  font-weight: bold;
  line-height: 1px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  font-size: 26px;
  padding: 0 !important;
}

.ol-full-screen-false {
  @extend .bg-success-dark;
  color: #fff;
}

.ol-full-screen-true {
  @extend .bg-purple;
}

.trip-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  padding: 1px !important;
  border-bottom: 1px solid #00b3ff38 !important;
  align-items: flex-start;
}

.select-handle {
  position: absolute;
  top: 44px;
  right: 77px;
  content: '';
  width: 37px;
  height: 37px;
  margin: 0 22px 0 0;
  background: rgba(167, 167, 167, 0);
  cursor: pointer;
  border-radius: 50%;
  /* filter: blur(3px); */
  z-index: 99999999;
}

.modal-drag-handle {
  cursor: grab !important;
}

.modal-drag-handle:active {
  cursor: grabbing !important;
}

.drag-handle {
  // display: none;
  position: absolute;
  /* top: 0; */
  right: -11px;
  // content: '';
  margin: 7px 0 0 0;
  width: 77px;
  height: 88%;
  background: #a7a7a700;
  cursor: grab !important;
  border-radius: 15px 0 0 15px;
  filter: blur(3px);
  z-index: 9999999999999;
}

.drag-handle:active {
  cursor: grabbing !important;
}

.drag-dest-handle {
  visibility: hidden;
  cursor: grab !important;
}

.bookingAddressDragger,
.bookingAddressDragger:hover,
.bookingAddressDragger:focus {
  cursor: grab;
}

.bookingAddressDragger:active,
.bookingAddressDragger:target {
  cursor: grabbing !important;
}

.trip-row:hover .drag-handle {
  // display: block;
  box-shadow: 0 0 8px 2px #777777 !important;
}

.shadow777 {
  box-shadow: 0 0 8px 2px #777777 !important;
}

// .dragging,
.react-draggable-dragging {
  position: relative;
  // pointer-events: none;
  z-index: 999999999999;
  opacity: 0.77;
  // height: 77px;
  overflow: hidden;
  cursor: grabbing;
}

.dragover {
  pointer-events: none;
  background: #77777777 !important;
  cursor: grabbing;
}

.route-mode {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #000000;
  text-align: center;
  overflow: hidden;
  padding: 6px 0 0 0;
  z-index: 9999999;
  // transition: all 0.5s ease-in-out;
}

.mt30 {
  margin: 30px 0 0 0;
}

.mt88 {
  margin: 88px 0 0 0;
}

.scrollWhenDrag {
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollWhenDrag::-webkit-scrollbar {
  display: none;
}

.white-lines-box {
  display: inline-flex;
  width: 100%;
  text-align: center;
  padding: 0 10px;
  background: rgba(51, 51, 51, 0.2);
  overflow: hidden;
  justify-content: space-between;
}

.white-line {
  width: 5rem;
  height: 5px;
  margin-left: 20px;
  background: #fff;
}

.route-mode-buttons-box {
  margin: -30px 0 0 0;
}

.route-mode-buttons-box button {
  padding: 12px 14px;
  font-size: 12px;
}

// .route-mode::before {
//   content: '';
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background: repeating-linear-gradient(45deg, #000 0, #000 40px, #fff 40px, #fff 80px);
// }

// .route-mode::after {
//   opacity: 0.5;
//   content: '';
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   background: repeating-linear-gradient(-45deg, #000 0, #000 40px, #fff 40px, #fff 80px);
//   mix-blend-mode: difference;
// }

.btn-outline-success:hover {
  background: #009130 !important;
}
// .btn-outline-danger,
.btn-outline-danger:hover {
  color: white;
  background: #bb4c4c !important;
}

.blur4 {
  filter: blur(4) !important;
}

.blur7 {
  -webkit-filter: blur(7) !important;
  -moz-filter: blur(7) !important;
  -o-filter: blur(7) !important;
  -ms-filter: blur(7) !important;
  filter: blur(7) !important;
}

.blurry-text {
  text-shadow: 0px 0px 7px #828282;
  color: rgb(0 0 0 / 0%);
}

.p-relative {
  position: relative;
}
.alter-plan {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  display: none;
  .close {
    font-weight: 700;
    font-size: 30px;
    position: absolute;
    right: 1rem;
    top: 0.75rem;
    color: red;
    cursor: pointer;
  }
}

.c-pointer {
  cursor: pointer;
}

input:disabled {
  pointer-events: none;
}

.onTripList {
  box-shadow: 0 0 0 transparent !important;
}

.flex-full-width {
  // flex: 1 1 100%;
  flex-basis: 100%;
}

.pagination {
  display: block;
  width: 100%;
  margin: 20px auto;
  text-align: center;
  button {
    @extend .shadow-gray;
    // @extend .w-fit;
    border: none;
    border-radius: 50%; // Border-radius to make it round
    // width: 40px;
    height: 40px;
    margin: 5px;
    padding: 0 15px;
    padding: '0 10px';
    font-size: 16px;
    cursor: pointer;

    &.active {
      background-color: #007bff;
      color: white;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  // Responsive styling for smaller screens
  @media (max-width: 768px) {
    .pagination {
      margin: 10px;

      button {
        width: 30px;
        height: 30px;
        font-size: 14px;
      }
    }
  }
}

@keyframes smoke {
  0% {
    transform: skew(0deg, 0deg);
  }
  50% {
    transform: skew(10deg, 10deg);
  }
  100% {
    transform: skew(0deg, 0deg);
  }
}

@keyframes animateHeart {
  // scale down and scale up faster in irregular intervals to get the throbbing effect
  0% {
    transform: scale(0.8);
  }
  5% {
    transform: scale(0.9);
  }
  10% {
    transform: scale(0.8);
  }
  15% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.8);
  }
}

// @keyframes jello {
//   11.1% {
//     transform: none;
//   }
//   22.2% {
//     transform: skewX(-9deg) skewY(-9deg);
//   }
//   33.3% {
//     transform: skewX(4deg) skewY(4deg);
//   }
//   44.4% {
//     transform: skewX(-2deg) skewY(-2deg);
//   }
//   55.5% {
//     transform: skewX(1.5625deg) skewY(1.5625deg);
//   }
//   66.6% {
//     transform: skewX(-0.78125deg) skewY(-0.78125deg);
//   }
//   77.7% {
//     transform: skewX(0.390625deg) skewY(0.390625deg);
//   }
//   88.8% {
//     transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
//   }
//   100% {
//     transform: none;
//   }
// }

@keyframes jumpInfinite {
  0% {
    margin-top: 0;
  }
  50% {
    margin-top: 20px;
  }
  100% {
    margin-top: 0;
  }
}

.showOnPrintOnly {
  display: none;
  // visibility: hidden !important;
}

.quicknote-box {
  text-align: left;
  max-height: 97px;
  overflow-y: auto;
  scrollbar-width: none !important; /* For Firefox */
  -ms-overflow-style: none !important; /* For Internet Explorer and Edge */
  // background: linear-gradient(1deg, #ebedef, transparent);
  border-radius: 15px;
}

// ​.quicknote-box::-webkit-scrollbar,
// ​.quicknote-box::-scrollbar {
//   display: none !important;
//   visibility: hidden !important;
//   background: transparent;
//   width: 0px !important; /* For Chrome, Safari, and Opera */
// }

.quicknote {
  display: inline-block;
  margin: 0 0 5px 10px;
  font-size: 12px;
}

.qn-content {
  display: inline-block;
  padding: 1px 7px;
  border-radius: 17px;
  color: #009130;
  background: #eefff3;
  max-width: 177px;
  max-height: 20px;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0px 0px 3px 0px #b9b9b9;
}

.delete-qn {
  display: none;
  // position: absolute;
  // margin: -7px 0 0 -7px;
  position: relative;
  margin: 0px -11px 3px -6px;
  width: 17px;
  height: 17px;
  background: #e55353;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}

.qn-content:hover ~ .delete-qn,
.delete-qn:hover {
  display: inline-block;
}

.growQn,
.growNotes {
  @extend .noshadow;
  overflow: hidden;
  // transition: all 3s;
  // -webkit-transition: all 3s;
}

.growQn {
  height: 27px;
}

.growNotes {
  max-height: 144px;
}

.growQn:hover,
.growNotes:hover {
  max-height: fit-content;
  // transition: all 3s;
  // -webkit-transition: all 3s;
  // height: 177px;
}

.notesTextArea {
  height: fit-content;
}

.unrouted {
  background: #3398fe;
}

.delete-date {
  @extend .shadow-gray;
  @extend .bg-danger;
  line-height: 8px;
  padding: 3px 5px 7px 5px !important;
  margin: 0 1px 0 0;
  color: #fff;
}

// .tripAreaOptions {
//   display: flex;
//   position: absolute;
//   left: 5px;
//   align-items: center;
//   /* justify-content: flex-start; */
//   /* text-align: left; */
//   width: 27px;
//   /* height: 37px; */
//   /* margin: 0 0 0 -3px; */
//   cursor: pointer;
//   /* float: left; */
//   align-content: center;
//   /* flex-direction: row;*/
// }
.tripAreaOptions,
.options-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 27px;
  height: 37px;
  cursor: pointer;
}

.tripAreaOptions {
  margin: 0 0 0 7px;
}

.tripSelected {
  background: #dcf7e8;
}

.errorBoundary {
  display: grid;
  grid-gap: 15px;
  margin: 0 auto;
  width: 100vw;
  text-align: center;
  font-weight: bold;
  height: 100vh;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
}

.octagonMiniWrap {
  margin: 3px 0 0px 0;
  width: 13px;
  height: 13px;
  float: left;
  position: relative;
  overflow: hidden;
}
.octagonWrap {
  margin: 3px 0 0 0;
  width: 16px;
  height: 16px;
  float: left;
  position: relative;
  overflow: hidden;
}
.octagon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  transform: rotate(45deg);
  background: #777;
  // border: 3px solid red;
}
.octagon:before {
  position: absolute;
  /* There needs to be a negative value here to cancel
     * out the width of the border. It's currently -3px,
     * but if the border were 5px, then it'd be -5px.
     */
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  transform: rotate(45deg);
  content: '';
  border: inherit;
}

.bg-success-dark {
  background: #077077 !important;
}

.form-check {
  display: inline-flex;
  align-items: center;
  width: fit-content;
  margin: 0 0 7px 0;
}

.infoTabs {
  margin: 0 auto !important;
  // background: transparent !important;
  box-shadow: none !important;
  // margin: 0 auto 18px auto !important;
  // overflow: hidden;
}

#infoTabsNav {
  @extend .minw303;
  // display: grid;
  // grid-template-columns: repeat(4, 88px);
  // justify-content: center;
  // padding: 1px;
  box-shadow: none !important;
  // overflow: hidden;
}

#infoTabsNav li {
  flex-grow: 0 !important;
}

#infoTabsNav li a {
  // background: #fff;
  width: 77px;
}

.noshadow {
  box-shadow: none !important;
}

.late {
  border-radius: 25px;
  padding: 0 5px 3px 5px;
  color: white;
  background: red;
  font-weight: bold;
  margin: 0 0 -2px 0;
  line-height: 13px;
}

.input77 {
  @extend .shadow-hover;
  width: 77px !important;
  text-align: center;
}

.simple-field {
  @extend .shadow-0;
  @extend .border-bottom-1;
  padding: 5px 1px 5px 5px !important;
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-radius: 0 !important;
  background: transparent !important;
  font-weight: bold;
}

.footer {
  border: none;
  // background: linear-gradient(0deg, rgb(7 17 77 / 17%), transparent);
  background: #151741;
  color: #888;
}

@media (max-width: 767.98px) {
  .sidebar-backdrop.fade {
    opacity: 0;
    display: none;
  }
}

@media print {
  header,
  footer,
  .footer,
  aside,
  // form,
  .sidebar,
  .tripOptionsButton,
  .hideOnPrint {
    display: none !important;
    visibility: hidden !important;
  }

  .showOnPrintOnly {
    display: block !important;
    visibility: visible !important;
  }

  .header {
    display: none !important;
    visibility: hidden !important;
  }

  .tripsAreaSearchBox {
    display: none !important;
    text-overflow: initial;
    visibility: hidden !important;
  }

  .text-truncate {
    overflow: auto;
    white-space: normal;
  }

  html:not([dir='rtl']) .wrapper,
  .sidebar:not(.sidebar-end) ~ * {
    margin-left: 0;
    padding-left: 0;
  }
  // a:after {
  //   content: '(' attr(href) ')';
  // }

  // .print-landscape {
  //   transform: rotate(-90deg);
  //   -webkit-transform: rotate(-90deg);
  //   -moz-transform: rotate(-90deg);
  //   filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  // }

  @page :first {
    margin-top: 0.1cm;
  }
  @page {
    size: portrait;
  }
  @page rotated {
    page-orientation: rotate-left;
  }

  .print-landscape {
    page: rotated;
    transform-origin: top left;
    transform: translateX(100vw) rotate(90deg);
    width: 100vh;
    height: 100vw;
  }
}
