.full-container {
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

.full-container:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // background: linear-gradient(88deg, rgba(53, 53, 255, 0.77), rgb(0 90 255 / 77%));
  // background: linear-gradient(88deg, rgb(0 135 243 / 87%), rgb(94 182 255 / 91%));
  // background: linear-gradient(88deg, rgb(101 187 255 / 91%), rgb(0 178 255 / 61%));
  // background: linear-gradient(88deg, rgb(0 49 255 / 71%), rgb(0 255 234 / 59%));
  background: linear-gradient(88deg, #88088888, #001d74de);
  /* background-color: #00303b; */
  /* background-color: #2a5555; */
  opacity: 0.9;
  z-index: 1;
}

video.bg-video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
  background: url('../assets/images/landing/bg-mobile-fallback.webp') #2a5555 no-repeat center
    center scroll;
  background-size: cover;
  filter: contrast(0.5);
}

@media (pointer: coarse) and (hover: none) {
  .full-container {
    background: url('../assets/images/landing/bg-mobile-fallback.webp') #2a5555 no-repeat center
      center scroll;
    background-size: cover;
    background-attachment: fixed;
    min-width: 100%;
    min-height: 100%;
  }
  body video {
    display: none;
  }
}
.masthead {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

.masthead:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 0 50% 50% 0;
  // background: linear-gradient(54deg, rgb(7 0 255 / 33%), #ffffff29);
  // background: linear-gradient(54deg, rgb(0 228 255 / 44%), rgba(255, 255, 255, 0.1607843137));
  // background: linear-gradient(54deg, rgb(0 228 255 / 37%), rgba(255, 255, 255, 0.1607843137));
  // background: linear-gradient(54deg, rgb(76 25 166 / 58%), #4e50ffba);
  background: linear-gradient(54deg, rgba(76, 25, 166, 0.58), rgba(78, 80, 255, 0.7294117647));
  // backdrop-filter: blur(3px);
  /* background-color: #e9ecef73; */
  /* background-color: #ffffff9e; */
  /* background-color: rgba(0, 0, 0, 0.85); */
}
.masthead .masthead-content {
  position: relative;
  /* max-width: 40rem; */
  padding: 7rem 0;
}
.masthead .masthead-content h1,
.masthead .masthead-content .h1 {
  color: #fff;
  text-shadow: -3px 3px 9px black;
  font-size: 2.5rem;
  /* background: linear-gradient(45deg, #ffffff3b, transparent); */
}
.masthead .masthead-content p {
  font-size: 1.2rem;
}
.masthead .masthead-content p strong {
  font-weight: 700;
}
.masthead .masthead-content .input-group-newsletter input {
  height: auto;
  width: 100%;
  font-size: 1rem;
  padding: 1rem;
}
.masthead .masthead-content .input-group-newsletter button {
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: calc(1rem + 2px);
}

@media (min-width: 992px) {
  .masthead {
    /* height: 100%; */
    /* width: 75vw; */
    min-height: 0;
    padding-bottom: 0;
  }
  /* .masthead:before { */
  /* transform: skewX(-9deg); */
  /* transform-origin: top right; */
  /* } */
  /* .masthead .masthead-content {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 9rem;
    } */
  /* .masthead .masthead-content h1,
    .masthead .masthead-content .h1 {
      font-size: 3.5rem;
    }
    .masthead .masthead-content p {
      font-size: 1.3rem;
    } */
}
@media (min-width: 1200px) {
  /* .masthead {
      width: 65vw;
    } */
}
/* .social-icons {
    position: relative;
    z-index: 2;
  }
  .social-icons .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 4rem;
    width: 4rem;
    border-radius: 100rem;
  } */

/* @media (min-width: 992px) { */
/* .social-icons {
      position: absolute;
      height: 100%;
      top: 0;
      right: 2.5rem;
      width: auto;
    } */
/* } */

.hero-logo {
  display: block;
  margin: 0 10px 0 0;
  padding: 1px;
  width: 77px;
  height: 77px;
  background: #00090099;
  box-shadow: -2px 2px 15px 0 #333;
  border-radius: 25px;
}

.projects-section {
  padding: 3rem 5px;
  position: relative;
  font-size: 22px;
  /* background: #0dcaf08f; */
}

.projects-section .container {
  position: relative;
  max-width: 909px;
  border-radius: 50px;
  padding: 3rem 0;
  text-shadow: -2px 2px 7px black;
  background: #ffffff9c;
  z-index: 1;
  background: linear-gradient(88deg, #00000033, transparent);
  backdrop-filter: blur(33px);
}

.projects-section .container img {
  display: block;
  margin: 33px auto;
  width: 77px;
  height: 77px;
  border: 17px solid #33333363;
  border-radius: 50%;
  box-sizing: content-box;
  box-shadow: 0px 0px 17px 7px #333;
}

.projects-section .featured-text {
  color: #fff;
  padding: 2rem 0;
}

// .featured-text p,
// .project-text p {
//   opacity: 0.7;
// }

@media (min-width: 992px) {
  .projects-section .featured-text {
    padding: 0 0 0 2rem;
    border-left: 0.5rem solid #64a19d;
  }
}

.projects-section .project-box {
  margin-bottom: 155px !important;
}

.projects-section .project-text {
  color: #fff;
  // padding: 1rem 0 7rem 0;
  font-size: 90%;
}
// @media (min-width: 992px) {
//   .projects-section {
//     margin-bottom: 92px !important;
//   }
//   .projects-section .project-text {
//     padding: 1rem 0 3rem 0;
//   }
// }

.signup-section {
  position: relative;
  padding: 10rem 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 75%, #000 100%);
  /* background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 75%, #000 100%),
      url("../assets/img/bg-signup.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  z-index: 1;
}

.contact-section {
  padding-top: 5rem;
  position: relative;
  z-index: 1;
}
// .contact-section .card {
//   border: 0;
//   border-bottom: 0.25rem solid #64a19d;
// }
.contact-section .card h4,
.contact-section .card .h4 {
  font-size: 0.8rem;
  font-family: 'Varela Round', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  text-transform: uppercase;
  letter-spacing: 0.15rem;
}
.contact-section .card hr {
  opacity: 1;
  border-color: #64a19d;
  border-width: 0.25rem;
  width: 3rem;
}
.contact-section .social {
  margin-top: 5rem;
}
.contact-section .social a {
  text-align: center;
  height: 3rem;
  width: 3rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  line-height: 3rem;
  color: rgba(255, 255, 255, 0.3);
}
.contact-section .social a:hover {
  color: rgba(255, 255, 255, 0.5);
}
.contact-section .social a:active {
  color: #fff;
}

.footer-landing {
  position: relative;
  z-index: 1;
  padding: 5rem 0;
}
