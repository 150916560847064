.stripe-card {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  padding: 20px;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: stretch;
  justify-content: center;
}

.stripe-card div[class^='__PrivateStripeElement'] {
  padding-top: 11px !important;
  border: none !important;
  display: block !important;
  background: #292929de !important;
  position: relative !important;
  opacity: 1 !important;
  box-shadow: 7px 5px 13px #333;
  border-radius: 5px;
}
